var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', [_c('b-modal', {
    attrs: {
      "id": "buy-gems",
      "hide-footer": true,
      "size": "md",
      "modal-class": _vm.eventClass
    }
  }, [_c('div', {
    staticClass: "header-wrap container",
    attrs: {
      "slot": "modal-header"
    },
    slot: "modal-header"
  }, [_vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 text-center"
  }, [_vm.eventName === 'fall_extra_gems' ? _c('img', {
    attrs: {
      "alt": _vm.$t('supportHabitica'),
      "srcset": require("@/assets/images/gems/fall-header.png") + ", " + require("@/assets/images/gems/fall-header@2x.png") + " 2x, " + require("@/assets/images/gems/fall-header@3x.png") + " 3x",
      "src": require("@/assets/images/gems/fall-header.png")
    }
  }) : _vm.eventName === 'spooky_extra_gems' ? _c('img', {
    attrs: {
      "alt": _vm.$t('supportHabitica'),
      "srcset": require("@/assets/images/gems/spooky-header.png") + ", " + require("@/assets/images/gems/spooky-header@2x.png") + " 2x, " + require("@/assets/images/gems/spooky-header@3x.png") + " 3x",
      "src": require("@/assets/images/gems/spooky-header.png")
    }
  }) : _c('img', {
    attrs: {
      "alt": _vm.$t('supportHabitica'),
      "srcset": require("@/assets/images/gems/support-habitica.png") + ", " + require("@/assets/images/gems/support-habitica@2x.png") + " 2x, " + require("@/assets/images/gems/support-habitica@3x.png") + " 3x",
      "src": require("@/assets/images/gems/support-habitica.png")
    }
  })])])]), _vm.currentEvent && _vm.currentEvent.promo && _vm.currentEvent.promo === 'g1g1' ? _c('div', {
    staticClass: "gift-promo-banner d-flex justify-content-around align-items-center px-4",
    on: {
      "click": _vm.showSelectUser
    }
  }, [_vm._m(1), _c('div', {
    staticClass: "d-flex flex-column announce-text text-center"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t('g1g1')) + " ")]), _c('small', {
    staticClass: "px-1 mt-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('g1g1Details')) + " ")])]), _vm._m(2)]) : _vm._e(), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row text-center"
  }, [_vm._m(3)]), _vm._m(4), _c('div', {
    staticClass: "row gem-deck"
  }, _vm._l(_vm.gemsBlocks, function (gemsBlock) {
    return _c('div', {
      key: gemsBlock.key,
      staticClass: "text-center col-3",
      class: {
        active: _vm.selectedGemsBlock === gemsBlock
      }
    }, [_c('div', {
      staticClass: "gem-icon",
      domProps: {
        "innerHTML": _vm._s(_vm.icons[gemsBlock.key])
      }
    }), _c('div', {
      staticClass: "gem-count"
    }, [_vm._v(" " + _vm._s(gemsBlock.gems) + " ")]), _vm._o(_c('div', {
      staticClass: "gem-text"
    }, [_vm._v(" " + _vm._s(_vm.$t('gems')) + " ")]), 0, gemsBlock.key), !_vm.isSelected(gemsBlock) ? _c('button', {
      staticClass: "btn btn-primary gem-btn",
      on: {
        "click": function click($event) {
          return _vm.selectGemsBlock(gemsBlock);
        }
      }
    }, [_vm._v(" " + _vm._s("$".concat(gemsBlock.price / 100)) + " ")]) : _c('button', {
      staticClass: "btn btn-success gem-btn",
      on: {
        "click": function click($event) {
          return _vm.selectGemsBlock(gemsBlock);
        }
      }
    }, [_vm._o(_c('div', {
      staticClass: "svg-icon check text-white mx-auto",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.check)
      }
    }), 1, gemsBlock.key)]), gemsBlock.originalGems ? _c('span', {
      staticClass: "small-text original-gems"
    }, [_vm._v(" " + _vm._s(_vm.$t('usuallyGems', {
      originalGems: gemsBlock.originalGems
    })) + " ")]) : _vm._e()]);
  }), 0), _c('payments-buttons', {
    attrs: {
      "disabled": !_vm.selectedGemsBlock,
      "stripe-fn": function stripeFn() {
        return _vm.redirectToStripe({
          gemsBlock: _vm.selectedGemsBlock
        });
      },
      "paypal-fn": function paypalFn() {
        return _vm.openPaypal({
          url: _vm.paypalCheckoutLink,
          type: 'gems',
          gemsBlock: _vm.selectedGemsBlock
        });
      },
      "amazon-data": {
        type: 'single',
        gemsBlock: _vm.selectedGemsBlock
      }
    }
  }), _vm.eventName === 'fall_extra_gems' || _vm.eventName === 'spooky_extra_gems' ? _c('div', {
    staticClass: "d-flex flex-column justify-content-center"
  }, [_c('h4', {
    staticClass: "mt-3 mx-auto"
  }, [_vm._v(" " + _vm._s(_vm.$t('howItWorks')) + " ")]), _c('small', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('gemSaleHow', {
    eventStartMonth: _vm.eventStartMonth,
    eventStartOrdinal: _vm.eventStartOrdinal,
    eventEndOrdinal: _vm.eventEndOrdinal
  })) + " ")]), _c('h4', {
    staticClass: "mt-3 mx-auto"
  }, [_vm._v(" " + _vm._s(_vm.$t('limitations')) + " ")]), _c('small', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('gemSaleLimitations', {
    eventStartMonth: _vm.eventStartMonth,
    eventStartOrdinal: _vm.eventStartOrdinal,
    eventEndMonth: _vm.eventEndMonth,
    eventEndOrdinal: _vm.eventEndOrdinal
  })) + " ")])]) : _vm._e()], 1)])], 1) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "close-icon svg-icon inline icon-12",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon svg-gifts left-gift",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gifts)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon svg-gifts right-gift",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gifts)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "col-12 text-leadin"
  }, [_vm._v(" " + _vm._s(_vm.$t('gemBenefitLeadin')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row gem-benefits pb-2"
  }, _vm._l([1, 2, 3, 4], function (benefit) {
    return _c('div', {
      key: benefit,
      staticClass: "col-md-6 d-flex pl-4 pr-0 pb-3"
    }, [_c('div', {
      staticClass: "d-flex bubble justify-content-center align-items-center"
    }, [_c('div', {
      staticClass: "svg-icon check mx-auto",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.check)
      }
    })]), _c('p', {
      staticClass: "small-text pl-2 mb-0"
    }, [_vm._v(" " + _vm._s(_vm.$t("gemBenefit".concat(benefit))) + " ")])]);
  }), 0);

}]

export { render, staticRenderFns }